export default {
  percent: {
    parse(x) {
      x = x.replace(/[-.[0-9]]/g, "");
      x = x.trim();
      return parseFloat(x) / 100;
    },

    toString(x) {
      return (100 * x).toFixed(2) + "%";
    }
  },

  number: {
    parse(x) {
      x = x.replace(/[-.[0-9]]/g, "");
      x = x.trim();
      return parseFloat(x);
    },

    toString(x) {
      console.log(x);
      return x.toFixed(3);
    }
  }
};
