<template>
  <div
    class="scenario-pill"
    :class="{ selected: isSelected }"
    @click="$emit('set-scenario', id)"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  computed: {
    isSelected() {
      return this.selection === this.id;
    },
  },

  props: {
    title: String,
    selection: String,
    id: String,
    auto: String,
    values: Object,
  },
};
</script>


<style scoped>
.scenario-pill {
  background: #c9d7e3;
  display: inline-block;
  border-radius: 2px;
  padding: 3px 10px;
  margin: 3px 2px;
  font-size: 12px;
  font-weight: 600;
  color: #0b2545;
  cursor: pointer;
  width: 100px;
}

.scenario-pill.selected {
  background: #13315c;
  color: white;
}
</style>