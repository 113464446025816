<template>
  <h3>Simulating the Impacts of Policy</h3>
  <p>
    There is a
    <a href="https://en.wikipedia.org/wiki/Velocity_of_money"
      >well-defined relationship in economics</a
    >
    that links the money supply, inflation, real GDP and the velocity of money.
    This calculator uses this formula so you can stress test the plausibility of
    the the Federal Reserve's forecasts and experiment with your own monetary
    policy.
  </p>
  <div class="formula">
    Velocity (V) x Money Supply (M) = Prices (P) x Real GDP (Q)
  </div>
  <h3>Choose a Scenario</h3>
  <div class="scenarios">
    <Scenario
      title="Shock and Awe Hiking"
      id="shockAndAwe"
      :values="getScenario('shockAndAwe')"
      @set-scenario="setScenario"
      :selection="scenario"
    />
    <Scenario
      title="Better 2023 Trajectory"
      id="betterTrajectory"
      :values="getScenario('betterTrajectory')"
      @set-scenario="setScenario"
      :selection="scenario"
    />
    <Scenario
      title="Fed 2022 Projections"
      id="fed2022"
      :values="getScenario('fed2022')"
      @set-scenario="setScenario"
      :selection="scenario"
    />
    <Scenario
      title="Fed 2023 Projections"
      id="fed2023"
      :values="getScenario('fed2023')"
      @set-scenario="setScenario"
      :selection="scenario"
    />
    <Scenario
      title="Fed 2024 Projections"
      id="fed2024"
      :values="getScenario('fed2024')"
      @set-scenario="setScenario"
      :selection="scenario"
    />
  </div>

  <h3>...or Build Your Own</h3>
  <div class="calc-boxes">
    <CalcBox
      id="V"
      label="Velocity"
      :auto="auto"
      :values="values"
      parser="percent"
      @set-auto="setAuto"
      @set-value="setValue"
    />
    <CalcBox
      id="M"
      label="Money Supply"
      :auto="auto"
      :values="values"
      parser="percent"
      @set-auto="setAuto"
      @set-value="setValue"
    />
    <CalcBox
      id="P"
      label="Prices"
      :auto="auto"
      :values="values"
      parser="percent"
      @set-auto="setAuto"
      @set-value="setValue"
    />
    <CalcBox
      id="Q"
      label="Real GDP"
      :auto="auto"
      :values="values"
      parser="percent"
      @set-auto="setAuto"
      @set-value="setValue"
    />
  </div>
</template>

<script>
import CalcBox from "./components/CalcBox.vue";
import Scenario from "./components/Scenario.vue";

export default {
  name: "App",
  components: {
    CalcBox,
    Scenario,
  },

  data() {
    // https://www.federalreserve.gov/monetarypolicy/fomcprojtabl20220615.htm
    // https://coolors.co/134074-13315c-0b2545-c9d7e3-f5f5f5
    const fed2022 = this.calculateData({
      auto: "V",
      values: { V: 0.0, M: -0.01, P: 0.052, Q: 0.017 },
    });
    const fed2023 = this.calculateData({
      auto: "V",
      values: { V: 0.0, M: -0.06, P: 0.026, Q: 0.017 },
    });
    const fed2024 = this.calculateData({
      auto: "V",
      values: { V: 0.0, M: -0.11, P: 0.022, Q: 0.019 },
    });
    const shockAndAwe = this.calculateData({
      auto: "Q",
      values: { V: -0.03, M: -0.06, P: 0.04, Q: 0.0165 },
    });
    const betterTrajectory = this.calculateData({
      auto: "Q",
      values: { V: 0.1, M: -0.04, P: 0.05, Q: 0.0165 },
    });

    return {
      scenarios: {
        fed2022,
        fed2023,
        fed2024,
        shockAndAwe,
        betterTrajectory,
      },
      scenario: "shockAndAwe",
      auto: fed2022.auto,
      values: Object.assign({}, fed2022.values),
    };
  },

  methods: {
    setAuto(id) {
      this.auto = id;
    },

    getScenario(id) {
      return "undefined" === typeof this.data ? {} : this.data.scenarios[id];
    },

    calculateData(data) {
      const { auto, values } = data;
      let V = 1 + values.V;
      let M = 1 + values.M;
      let P = 1 + values.P;
      let Q = 1 + values.Q;
      switch (auto) {
        case "V":
          values.V = (P * Q) / M - 1;
          break;
        case "M":
          values.M = (P * Q) / V - 1;
          break;
        case "P":
          values.P = (V * M) / Q - 1;
          break;
        case "Q":
          values.Q = (V * M) / P - 1;
          break;
        default:
          break;
      }
      return { auto, values };
    },

    setScenario(scenario) {
      this.scenario = scenario;
      this.auto = this.scenarios[scenario].auto;
      this.values = this.scenarios[scenario].values;
    },

    setValue(payload) {
      this.scenario = null;
      this.values[payload.id] = payload.value;
      this.values = this.calculateData({
        auto: this.auto,
        values: this.values,
      }).values;
    },
  },
};
</script>

<style>
#app {
  max-width: 850px;
  margin: 0 auto;
}

#app,
input {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  padding: 0 10px;
  box-sizing: border-box;
}

p {
  text-align: left;
}

h3 {
  margin-bottom: 0.5em;
}

.scenarios {
  margin: 0 auto;
  display: flex;
  vertical-align: top;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.calc-boxes {
  vertical-align: top;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 100%;
}

.formula {
  background: #FCFCFC;
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: "Courier New";
  font-weight: 600;
}

.chart {
  width: 600px;
  height: auto;
  display: inline-block;
  margin: 10px auto;
  padding: 0 10px;
}

.chart img {
  width: 100%;
}
</style>
