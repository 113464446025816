<template>
  <div class="calc-box" :class="{ auto: isAuto }">
    <div class="calc-box-label" @click="setAuto">{{ label }} Change</div>
    <div class="calc-box-method" @click="setAuto">
      {{ isAuto ? "Auto Calculated" : "Click to Calculate" }}
    </div>
    <div v-if="isAuto">
      <input class="calc-box-value" :value="value" disabled />
    </div>
    <div v-else>
      <input class="calc-box-input" :value="value" @change="setValue" />
    </div>
  </div>
</template>

<script>
import Parsers from "../library/Parsers.js";

export default {
  computed: {
    isAuto() {
      return this.id === this.auto;
    },

    value() {
      return Parsers[this.parser].toString(this.values[this.id]);
    },
  },

  props: {
    id: String,
    label: String,
    auto: String,
    values: Object,
    parser: {
      type: String,
      default: "percent",
    },
  },

  methods: {
    setAuto() {
      this.$emit("set-auto", this.id);
    },

    setValue(event) {
      const value = Parsers[this.parser].parse(event.target.value);
      this.$emit("set-value", { id: this.id, value: value });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.calc-box {
  height: 150px;
  border: 2px solid #f5f5f5;
  margin: 4px;
  width: 170px;
}

.calc-box-label {
  text-transform: uppercase;
  background: #f5f5f5;
  font-weight: 600;
  padding: 4px;
  font-size: 0.9em;
}

.auto {
  border: 2px solid #13315c !important;
}

.calc-box.auto .calc-box-label {
  background: #13315c;
  color: white;
}

.calc-box-method {
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  margin-top: 0.75em;
}

.calc-box-value,
.calc-box-input {
  font-size: 2em;
  max-width: 80%;
  margin: 23px auto 0;
  text-align: center;
  padding: 0;
}

input.calc-box-input {
  outline: none;
  border: 1px solid #f5f5f5;
}

input.calc-box-value {
  outline: none;
  border: 0;
  background: white;
}
</style>
